// NPM Requirements
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

// Components
import PublicEvent from './PublicEvent';
import Loader from '../shared/Loader';

// Actions
import eventActions from '../../actions/eventActions';

// Selectors
import { getAllEvents, getPaginationForEntity } from '../../selectors';

// Misc
import EventUtils from '../../modules/eventUtils';

import styles from './PublicEvents.module.postcss';

class PublicEventsList extends Component {
  constructor(props) {
    super(props);
    this.onPageChange = this.onPageChange.bind(this);
    this.fetchEvents = this.fetchEvents.bind(this);

    this.state = {
      page: 0,
      ready: false
    };
  }

  componentDidMount() {
    this.props.clearEvents().then(() => {
      this.fetchEvents();
      this.setState({
        ready: true
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.pod !== prevProps.pod ||
        this.props.location !== prevProps.location ||
        this.props.truck !== prevProps.truck ||
        this.state.page !== prevState.page) {
      this.fetchEvents();
    }
  }

  fetchEvents() {
    const page_date = moment().add(this.state.page, 'weeks');
    const start_date = page_date.startOf('week').format('M-D-YY');
    const end_date = page_date.endOf('week').format('M-D-YY');

    return this.props.fetchEvents({
      end_date,
      page: 1,
      page_size: 300,
      start_date,
    });
  }

  onPageChange(difference) {
    this.setState({
      page: this.state.page + difference,
    });

    if (this.list && window) {
      const list = findDOMNode(this.list);
      const destination = $(list).offset().top - 150;
      window.scrollTo(0, destination);
      // $('html, body').stop().animate({ scrollTop: destination }, 200);
    }
  }

  render() {
    const { events, truck, pod, showTrucks, showLocations, disableLoader, includeMeta, admin } = this.props;

    if (!this.state.ready) return null;

    let displayedEvents = events.map((event) => {
      return (
        <PublicEvent
          event={event}
          key={event.id}
          showTrucks={showTrucks}
          showLocations={showLocations}
          includeMeta={includeMeta}
          mode="list"
          admin={admin}
        />
      );
    });

    const weekOfDate = moment().add(this.state.page, 'weeks').isoWeekday(1).format('MMMM Do');
    let noEventsMessage = `No events scheduled for the week of ${weekOfDate}`;

    return (
      <div
        ref={(c) => { this.list = c; }}
      >
        <Loader entities="event" actions="fetch" disable={disableLoader}>
          {displayedEvents.length > 0 &&
            <div>
              {displayedEvents}
            </div>
          }

          {displayedEvents.length === 0 &&
            <p className="u-mt1 u-mb2 u-textCenter">{noEventsMessage}</p>
          }

          <div className={styles.EventNav}>
            <div className={styles.EventNav_back} onClick={() => this.onPageChange(-1)}>Previous week</div>
            <div className={styles.EventNav_date + " u-noMobile"}>Viewing week of {weekOfDate}</div>
            <div className={styles.EventNav_next} onClick={() => this.onPageChange(1)}>Next week</div>
          </div>
        </Loader>

      </div>
    );
  }
}

PublicEventsList.propTypes = {
  clearEvents: PropTypes.func,
  fetchEvents: PropTypes.func,
  events: PropTypes.array,
  pagination: PropTypes.object,
  truck: PropTypes.string,
  location: PropTypes.number,
  pod: PropTypes.string,
  includeLocations: PropTypes.bool,
  withActiveTrucks: PropTypes.bool,
  includeBookings: PropTypes.bool,
  showTrucks: PropTypes.bool,
  showLocations: PropTypes.bool,
  getBookingsFromEventsList: PropTypes.func,
  disableLoader: PropTypes.bool,
};

function mapStateToProps(state, props) {
  return {
    events: getAllEvents(state, props),
    pagination: getPaginationForEntity(state, 'event')
  };
}

function mapDispatchToProps(dispatch, props) {
  return {
    fetchEvents(localOptions) {
      const options = EventUtils.prepOptions(localOptions, props);
      return dispatch(eventActions.fetch(options));
    },
    clearEvents() {
      return dispatch(eventActions.clear());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PublicEventsList);
