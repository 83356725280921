// See React on Rails Pro's spec/loadable/README.md  for details regarding **.imports-X.** file extension & duplicate file structure.
import React from 'react';
import { hydrate } from 'react-dom';

import { loadableReady } from '@loadable/component';

import ClientApp from '../../../javascripts/App';

const App = (props, _railsContext, domNodeId) => {
  loadableReady(() => {
    hydrate(React.createElement(ClientApp, { ...props, railsContext: _railsContext }), document.getElementById(domNodeId));
  });
};

export default App;
