// NPM Requirements
import cn from 'classnames';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import Scroll from 'react-scroll';
import { Tooltip } from 'react-tippy';
import LazyLoad from 'react-lazyload';

// Components
import TruckHero from './TruckHero';
import PublicEvents from './PublicEvents';
import Ad from '../shared/Ad';
import Sidebar from './Sidebar';
import Rating from './Rating';
import Loader from '../shared/Loader';
import Meta from '../shared/Meta';
import TruckReviews from './TruckReviews';
import BookAFoodTruck from './BookAFoodTruck';
import TruckTease from './TruckTease';
import TruckFAQ from './TruckFAQ';

// Actions
import { clearServerRenderedPath } from '../../actions/uiActions';
import truckActions from '../../actions/truckActions';
import { getTruck, getCityConfig } from '../../selectors';

// Misc
import IsoMorphUtils from '../../modules/isoMorphUtils';
import { getUrl, intersperse } from '../../modules/helpers';
import TruckUtils from '../../modules/truckUtils';

import '../../../stylesheets/shared/components/ReactTippy.postcss';
import sidebarStyles from '../../../stylesheets/public/layouts/SidebarLayout.module.postcss';
import styles from './Truck.module.postcss';

const OFFSET = -144;

class Truck extends Component {
  constructor(props) {
    super(props);
    const match = props.computedMatch || props.match;
    this.id = match.params.id;
    this.state = {
      ready: false || IsoMorphUtils.ready(props)
    };
  }

  componentDidMount() {
    if (IsoMorphUtils.serverRendered(this.props)) {
      this.props.clearServerRenderedPath();
    } else {
      this.props.clearTrucks().then(() => {
        this.fetchTruck();
        this.setState({ ready: true });
      });
    }
  }

  componentWillUpdate(nextProps, nextState) {
    const currentMatch = this.props.computedMatch || this.props.match;
    const nextMatch = nextProps.computedMatch || nextProps.match;

    if ((currentMatch.params && nextMatch.params) &&
        (currentMatch.params.id !== nextMatch.params.id)) {
      this.id = nextMatch.params.id;
      this.fetchTruck();
    }
  }

  fetchTruck() {
    this.props.fetchTruck(this.id);
  }

  getRelatedTrucks(truck) {
    if (truck && truck.related_trucks && truck.related_trucks.length > 0) {
      return truck.related_trucks.map((relatedTruck) => {
        return (
          <div className={styles.TruckTease_wrapper + " u-grid-col u-grid-sm-half u-grid-md-third"} key={relatedTruck.id}>
            <TruckTease
              truck={relatedTruck}
            />
          </div>
        );
      });
    }

    return null;
  }

  getMenu(truck) {
    if (truck && truck.menu_items) {
      return truck.menu_items.map((menuItem) => {
        return (
          <div key={menuItem.id} className={styles.TruckMenuItem}>
            {menuItem.photo &&
              <div
                className={styles.TruckMenuItem_photo}
                style={{ backgroundImage: `url(${IsoMorphUtils.railsContext.imageBase}/${menuItem.photo})` }}
              />
            }
            <div className={styles.TruckMenuItem_details}>
              <p
                className={styles.TruckMenuItem_title}
              >
                {menuItem.name}
                {menuItem.price > 0 &&
                  <span className={styles.TruckMenuItem_price}>
                    ${menuItem.price}
                  </span>
                }
              </p>
              <p className={styles.TruckMenuItem_description}>{menuItem.description}</p>
            </div>
          </div>
        );
      });
    }

    return null;
  }

  renderTruckDetails(truck) {
    const detailMap = TruckUtils.getTruckBadgesMap(truck);

    const renderDetail = (imageName, imageAlt, description) => (
      <div
        className="u-grid-col u-grid-half u-grid-sm-third u-grid-md-quarter u-textCenter"
        key={`${truck.id}-${imageName}`}
      >
        <img src={`https://static.seattlefoodtruck.com/badges/${imageName}.png`} alt={imageAlt} />
        <p>{description}</p>
      </div>
    );

    return (
      <Scroll.Element name="about">
        <h4>About this Truck</h4>
        <div className={styles.TruckDetail_description}>
          {truck.description}
        </div>
        <div className={styles.TruckDetail_badges + " u-grid-row"}>
          {detailMap.map(({ description, imageName, show }) => (
            show(truck) && renderDetail(imageName, description, description)
          ))}
        </div>
      </Scroll.Element>
    );
  }

  renderSocial(truck, desktop) {
    if (!truck) {
      return null;
    }

    const classNames = cn(styles.TruckDetail_socialConnect, {
      'u-noMobile': desktop,
      'u-mobileOnlyFlex': !desktop,
    });

    let facebookLink = truck.facebook;
    if (facebookLink && !facebookLink.startsWith('http')) {
      facebookLink = 'http://' + facebookLink;
    }

    return (
      <div className={classNames}>
        {truck.facebook &&
          <Tooltip
            arrow
            duration={0}
            html={(<a href={truck.facebook} target="_blank">{truck.facebook}</a>)}
            interactive
          >
            <a
              href={facebookLink}
              target="_blank"
              rel="noopener noreferrer"
              className="icon icon-facebook"
            />
          </Tooltip>
        }
        {truck.instagram &&
          <Tooltip
            arrow
            duration={0}
            html={(<a href={truck.instagram} target="_blank">{truck.instagram}</a>)}
            interactive
          >
            <a
              href={`https://www.instagram.com/${truck.instagram}`}
              target="_blank"
              rel="noopener noreferrer"
              className="icon icon-instagram"
            />
          </Tooltip>
        }
        {truck.twitter &&
          <Tooltip
            arrow
            duration={0}
            html={(<a href={truck.twitter} target="_blank">{truck.twitter}</a>)}
            interactive
          >
            <a
              href={`https://twitter.com/${truck.twitter}`}
              target="_blank"
              rel="noopener noreferrer"
              className="icon icon-twitter"
            />
          </Tooltip>
        }
        {truck.yelp &&
          <Tooltip
            arrow
            duration={0}
            html={(<a href={truck.yelp} target="_blank">{truck.yelp}</a>)}
            interactive
          >
            <a
              href={truck.yelp}
              target="_blank"
              rel="noopener noreferrer"
              className="icon icon-yelp"
            />
          </Tooltip>
        }
      </div>
    );
  }

  renderNav(menu) {
    return (
      <ul>
        <li>
          <Scroll.Link
            offset={OFFSET}
            smooth
            to="about"
            activeClass="active"
          >
            About
          </Scroll.Link>
        </li>
        <li>
          <Scroll.Link
            offset={OFFSET}
            smooth
            to="schedule"
            activeClass="active"
          >
            Schedule
          </Scroll.Link>
        </li>
        {menu && menu.length > 0 &&
          <li>
            <Scroll.Link
              offset={OFFSET}
              smooth
              to="menu"
              activeClass="active"
            >
              Menu
            </Scroll.Link>
          </li>
        }
        <li>
          <Scroll.Link
            offset={OFFSET}
            smooth
            to="reviews"
            activeClass="active"
          >
            Reviews
          </Scroll.Link>
        </li>
      </ul>
    );
  }

  render() {
    const { truck, location, cityConfig } = this.props;
    if (!this.state.ready) return null;

    let categories = null;
    let categoriesString = null;
    if (truck && truck.food_categories) {
      categories = truck.food_categories.map((cat) => {
        return (
          <Link key={cat.id} to={`/search?query=${cat.id}`}>{cat.name}</Link>
        );
      });
      categories = intersperse(categories, ', ');
      categoriesString = _.map(truck.food_categories, _.iteratee('name')).join(', ');
    }

    let photos = 'https://static.seattlefoodtruck.com/photos/locationheader.png';
    if (truck && truck.photos && truck.photos.length > 0) {
      photos = _.map(truck.photos, (photo) => {
        return `${IsoMorphUtils.railsContext.imageBase}/${photo.file}`;
      });
    }

    let vehicleType = 'Food Truck';
    if (truck && truck.cart) { vehicleType = 'Food Cart'; }
    if (truck && truck.trailer) { vehicleType = 'Food Trailer'; }

    let description = truck ? truck.description : null;
    let image = photos.length > 0 ? photos[0] : null;

    const relatedTrucks = this.getRelatedTrucks(truck);
    const menu = this.getMenu(truck);
    const url = getUrl();

    let title = 'Food ' + cityConfig.vehicle_type + ' - ' + cityConfig.site_title;
    if (truck) {
      title = `${truck.name} - ${vehicleType} Schedule - ${categoriesString ? categoriesString + ' -' : ''} ${vehicleType} Reviews - Phone Number - Menu`;
    }

    return (
      <div>
        <Meta
          title={title}
          url={url}
          type="website"
          image={image}
          description={description}
          structuredData={{
            breadcrumbs: { listItems: [
              {
                "@type": "ListItem",
                "position": 2,
                "name": `${(truck ? truck.name : '')} Food ${cityConfig.vehicle_type}`,
                "item": cityConfig.base_url + location.pathname,
              }
            ]}
          }}
        />

        <Loader entities="truck" actions="fetch">
          {truck &&
            <TruckHero photos={photos}>
              <h1>{truck.name}</h1>
              {categories && <p>{categories}</p>}
              <div className={styles.TruckDetail_rating}>
                <Rating
                  rating={truck.yelp_rating}
                  ratingCount={truck.yelp_rating_count}
                  truck={truck.id}
                  yelp={truck.yelp}
                />
              </div>
            </TruckHero>
          }
        </Loader>

        <div className={"SecondaryHeader " + styles.TruckDetail_nav}>
          <Loader entities="truck" actions="fetch">
            {this.renderNav(menu)}
          </Loader>
          {this.renderSocial(truck, true)}
        </div>

        <div className={"PaddedSection " + styles.TruckDetail}>
          <div className={sidebarStyles.SidebarLayout}>
            <div className="Container">
              <div className={styles.TruckDetail__SidebarLayout_body}>
                <Loader entities="truck" actions="fetch">
                  {this.renderSocial(truck, false)}
                  {truck &&
                    <div>
                      <div className={styles.TruckDetail_section}>
                          { this.renderTruckDetails(truck) }
                      </div>

                      <Scroll.Element className={styles.TruckDetail_section} name="schedule">
                        <PublicEvents
                          truck={this.id}
                          withActiveTrucks
                          includeLocations
                          includeMeta={{
                            type: 'truck',
                            url: url,
                            truckName: truck.name,
                            image: image,
                            description: description
                          }}
                          showLocations
                          title={cityConfig.vehicle_type + " schedule"}
                        />
                      </Scroll.Element>

                      {menu && menu.length > 0 &&
                        <Scroll.Element className={styles.TruckDetail_section} name="menu">
                          <h4>Truck Menu</h4>
                          <div className={styles.TruckDetail_menuItems}>
                            {menu}
                          </div>
                        </Scroll.Element>
                      }

                      <Scroll.Element name="reviews">
                        <LazyLoad height={500} offset={100} once>
                          <h4>Customer Reviews</h4>
                          <TruckReviews truck={truck} cityConfig={cityConfig} />
                        </LazyLoad>
                      </Scroll.Element>

                      <TruckFAQ truck={truck} />

                      { relatedTrucks &&
                        <div className="u-mt2">
                          <h4>Customers also viewed</h4>
                          <hr />
                          <div className="u-grid-row">
                            {relatedTrucks}
                          </div>
                        </div>
                      }

                      {/* <div className="Advertisement u-mt2 u-mb1">
                        <Ad slot="7735243397" />
                      </div> */}
                    </div>
                  }
                </Loader>

              </div>

              <div className={sidebarStyles.SidebarLayout_sidebar}>
                <Sidebar>
                </Sidebar>
              </div>
            </div>
          </div>
        </div>

        <div className="u-noMobile">
          <BookAFoodTruck sticky />
        </div>
      </div>
    );
  }
}

Truck.propTypes = {
  fetchTruck: PropTypes.func,
  clearTrucks: PropTypes.func,
  truck: PropTypes.object,
  match: PropTypes.object,
  ui: PropTypes.object,
  clearServerRenderedPath: PropTypes.func,
  computedMatch: PropTypes.object,
  cityConfig: PropTypes.object,
};

function mapStateToProps(state, props) {
  return {
    truck: getTruck(state, props),
    ui: state.ui,
    cityConfig: getCityConfig(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearTrucks() {
      return dispatch(truckActions.clear());
    },
    fetchTruck(id) {
      dispatch(truckActions.fetchOne(id));
    },
    clearServerRenderedPath() {
      dispatch(clearServerRenderedPath());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Truck));
